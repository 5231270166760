import React, { useState } from 'react';
import { Badge, Box, Button, Drawer, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import BagProductRetro from '../../../BagRetro/BagProductRetro/BagProductRetro';

const GoToBagRetro = () => {

    let quantity = 0;
    const navigate = useNavigate();
    const [state, setState] = useState(false);

    let shop = useSelector((state) => state?.product?.shop)


    shop = JSON.parse(localStorage.getItem('shop'));

    const name = process.env.React_App_NAME
    const hostName = window.location.hostname;

    // console.log('shop', shop);
    const cart = useSelector((state) => state?.cart)
    const cartItems = cart?.cartItems;
    // console.log('cartItems', cartItems);
    const carts = cartItems?.filter(cartItem => cartItem?.shop_id == shop?.shop_id)


    carts.map(cart => quantity += cart.quantity)
    // console.log(quantity);

    let total = 0;
    let totalSellingPrice = 0;

    carts.map(cart => {
        let itemTotal;
        const { unit_selling_price, quantity, unit_discounted_price } = cart;
        const itemTotalSellingPrice = unit_selling_price * quantity;
        unit_discounted_price ? (itemTotal = unit_discounted_price * quantity) : (itemTotal = unit_selling_price * quantity)

        totalSellingPrice += itemTotalSellingPrice
        total += itemTotal

    })

    const CheckOutButton = styled(Button)(({ theme }) => ({
        textTransform: 'none',
        border: '1px solid #000000',
        borderRadius: '0px',
        objectFit: 'cover',
        width: '100%',
        fontSize: '0.833vw',
        fontWeight: '500',
        height: '32px',
        backgroundColor: '#000000',
        fontFamily: 'Quicksand',
        color: '#FFFFFF',
        height: '45px',
        '&:hover': {
            color: '#000000'
        },


    }));

    const handleClickCheckOutButton = () => {
        carts?.length == 0 ? (
            // dispatch(addToCart(product))
            alert('please add a product')
        ) : (hostName == name ? navigate(`/${shop?.shop_link}/bagInfo`) : navigate(`/bagInfo`))

    }


    const ColorButton = styled(Button)(({ theme }) => ({
        textTransform: 'none',
        backgroundColor: '#000000',
        border: '1px solid #000000',
        borderRadius: '0px',
        fontFamily: 'Quicksand',
        fontSize: '18px',
        fontWeight: '500',
        height: '48px',
        width: '100%',
        color: '#FFFFFF',
        '&:active': {
            backgroundColor: '#000000',
        },
        '&:hover': {
            backgroundColor: '#000000',
            color: 'white'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '16px'
        }

    }));
    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState(open);
    };
    const handleClick = () => {
        hostName == name ? navigate(`/${shop?.shop_link}/bag`) : navigate(`/bag`)
    }



    const list = () => (
        <Box
            sx={{ width: '32.91vw' }}
            role="presentation"

            onKeyDown={toggleDrawer(false)}
        >
            <Box onClick={toggleDrawer(false)} sx={{ marginTop: '85px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Badge badgeContent={quantity} color="error" sx={{ marginRight: '24px' }}>
                    <ShoppingBagIcon sx={{ color: ' #000000', width: '41px', height: '41px' }}></ShoppingBagIcon>
                </Badge>
                <Typography sx={{ fontSize: '28px', fontWeight: 500, color: ' #000000', fontFamily: 'Quicksand', lineHeight: '36px', color: ' #000000' }}>Your Bag</Typography>

            </Box>
            <BagProductRetro carts={carts}></BagProductRetro>

            {
                carts[0] ? (<>
                    <Box sx={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#FFFFFF' }}>
                        <hr />
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '20px', color: '#000000', marginX: '3.75vw' }}>
                            <Grid container spacing={{ md: 2 }} columns={{ md: 6 }} >
                                <Grid item md={12} sx={{ color: '#000000', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500, textAlign: 'center', fontFamily: 'Quicksand' }}>Total</Typography>

                                    {/* <Typography sx={{ fontSize: '16px', marginLeft: '80px', fontWeight: 500 }}>{total + totalVat}</Typography> */}
                                    <Typography sx={{ fontSize: '16px', fontWeight: 500 }}><span style={{ fontFamily: 'NotoSansBengali' }}>৳ </span>{total}</Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} sx={{ marginTop: '12px', width: '28%' }}>
                                <Grid item md={6}>
                                    <CheckOutButton
                                        sx={{ backgroundColor: '#FFFFFF', color: '#000000', }}
                                        size="small"
                                        onClick={toggleDrawer(false)}

                                    >Continue Shopping

                                    </CheckOutButton>
                                </Grid>
                                <Grid item md={6}>

                                    <CheckOutButton
                                        size="small"
                                        onClick={handleClickCheckOutButton}

                                    >Checkout

                                    </CheckOutButton>

                                </Grid>
                            </Grid>

                        </Box >
                    </Box >

                </>
                ) : (<Grid container spacing={1} sx={{ marginTop: '12px' }}>
                    <Grid item md={3}>

                    </Grid>
                    <Grid item md={6}>
                        <ColorButtonEmptyBag
                            size="small"
                            onClick={toggleDrawer(false)}

                        >Continue Shopping

                        </ColorButtonEmptyBag>
                    </Grid>
                    <Grid item md={3}>

                    </Grid>

                </Grid>)
            }


        </Box>
    );


    return (
        <>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <ColorButton
                    size="small"
                    onClick={toggleDrawer(true)}

                >Go to bag

                </ColorButton>
            </Box>
            <Drawer
                anchor='right'
                open={state}

                onClose={toggleDrawer(false)}
            >
                {list()}
            </Drawer>

            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <ColorButton
                    size="small"
                    onClick={handleClick}

                >Go to bag

                </ColorButton>
            </Box>
        </>
    );
};

export default GoToBagRetro;