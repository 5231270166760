import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import viewMore from '../../../../images/logo/viewmore_r.svg'
import viewLess from '../../../../images/logo/viewless.svg'
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import axios from '../../../../InstanceAxios/InstanceAxios'
import { Grid, Container } from '@mui/material';

const useStyles = makeStyles({
    bigText: {

        color: '#000000',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',


    },
    bigTextRes: {

        color: '#000000',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'left'

    },
    smText: {

        color: '#79747E',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        marginBottom: '12px',
        textAlign: 'left',
        display: 'flex',
        cursor: 'pointer'


    },
    smTextRes: {

        color: '#79747E',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        marginBottom: '0px',
        textAlign: 'left'


    },
    iconStyle: {
        width: '13.47px',
        color: '#79747E',
        marginRight: '10px'

    },
    socialMedia: {
        height: '30px',
        width: '30px',
        color: '#FFFFFF',
        paddingTop: '6px',
        paddingBottom: '6px',
        backgroundColor: '#D1D1D1',
        border: '1px solid #D1D1D1',
        borderRadius: '15px',
        marginRight: '12px',

    },

    poweredByLink: {
        color: '#79747E',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        paddingBottom: '48px',
        textDecoration: 'none',
        '&:hover': {
            color: '#000000'
        }
    }

});

const FooterTechne = () => {

    const navigate = useNavigate();
    let shop = useSelector((state) => state?.product?.shop)
    const [categories, setCategories] = useState([])
    const [anchorElNav, setAnchorElNav] = useState(null);

    const [display, setDisplay] = useState('none')

    const [displayContact, setDisplayContact] = useState('none')

    const hostName = window.location.hostname;

    const name = process.env.React_App_NAME

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    // console.log('footer', shop);


    shop = JSON.parse(localStorage.getItem('shop'));

    // console.log(shop);

    //popular Collections

    // if (shop?.shop_id) {

    const url = `/categories/allCategories?shop_id=${shop?.shop_id}`
    useEffect(() => {

        const fetchData = async () => {

            const response = await axios.get(url)
            // console.log(response.data);
            setCategories(response.data)
        }
        fetchData()

    }, [shop?.shop_id])
    // }

    // console.log(categories);
    // console.log(categories?.Product_Categories);

    const category = categories?.Product_Categories?.slice(0, 4);


    const classes = useStyles();

    const handleCollectionClick = () => {
        setCollectionOpen('block')
    }

    const handleClick = id => {
        hostName == name ? navigate(`/${shop?.shop_link}/categoryDetails/${id}`) : navigate(`/categoryDetails/${id}`)
    }



    return (
        <>
            <Grid container spacing={4} sx={{ paddingTop: '48px', paddingBottom: '24px', display: { xs: 'none', md: 'flex' } }}>
                <Grid item md={4} xs={12}>
                    <p className={classes.bigText}> Popular Collections</p>
                    <Box sx={{ marginLeft: '173px', textAlign: 'left' }}>
                        {
                            category?.map(category => <p key={category?.category_id} className={classes.smText} onClick={() => handleClick(category?.category_id)}> {category?.category}</p>)
                        }
                    </Box>

                </Grid>
                <Grid item md={4} xs={12} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ textAlign: 'left', marginLeft: '142px' }}>
                        <p className={classes.bigText}>Contact us</p>
                        <p className={classes.smText}>
                            <Box sx={{ marginRight: '8px' }}>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.4223 9.44507L10.5173 9.22757C10.0598 9.17507 9.60979 9.33257 9.28729 9.65507L7.90729 11.0351C5.78479 9.95507 4.04479 8.22257 2.96479 6.09257L4.35229 4.70507C4.67479 4.38257 4.83229 3.93257 4.77979 3.47507L4.56229 1.58507C4.47229 0.827568 3.83479 0.257568 3.06979 0.257568H1.77229C0.924787 0.257568 0.219786 0.962568 0.272286 1.81007C0.669786 8.21507 5.79229 13.3301 12.1898 13.7276C13.0373 13.7801 13.7423 13.0751 13.7423 12.2276V10.9301C13.7498 10.1726 13.1798 9.53507 12.4223 9.44507Z" fill="#79747E" />
                                </svg>
                            </Box>

                            {shop?.phone}</p>
                        <p className={classes.smText}>
                            <Box sx={{ marginRight: '8px' }}>
                                <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14 0H2C1.175 0 0.5075 0.675 0.5075 1.5L0.5 10.5C0.5 11.325 1.175 12 2 12H14C14.825 12 15.5 11.325 15.5 10.5V1.5C15.5 0.675 14.825 0 14 0ZM13.7 3.1875L8.3975 6.5025C8.1575 6.6525 7.8425 6.6525 7.6025 6.5025L2.3 3.1875C2.1125 3.0675 2 2.865 2 2.6475C2 2.145 2.5475 1.845 2.975 2.1075L8 5.25L13.025 2.1075C13.4525 1.845 14 2.145 14 2.6475C14 2.865 13.8875 3.0675 13.7 3.1875Z" fill="#79747E" />
                                </svg>
                            </Box>
                            {shop?.email}</p>
                    </Box>

                </Grid>
                <Grid item md={4} xs={12}>
                    <p className={classes.bigText}>Follow us</p>
                    <a href={`//${shop?.facebook_link}`} target='_blank'><svg className={classes.socialMedia} width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.403 7.00004H7V5.00004C7 3.96804 7.084 3.31804 8.563 3.31804H9.431C9.983 3.31804 10.431 2.87004 10.431 2.31804V1.06404C10.431 0.541037 10.03 0.0940375 9.508 0.0590375C8.904 0.0180375 8.299 -0.000962531 7.693 3.74688e-05C4.98 3.74688e-05 3 1.65704 3 4.69904V7.00004H1C0.448 7.00004 0 7.44804 0 8.00004V10C0 10.552 0.448 11 1 11L3 10.999V19C3 19.552 3.448 20 4 20H6C6.552 20 7 19.552 7 19V10.997L9.174 10.996C9.682 10.996 10.109 10.615 10.167 10.11L10.396 8.11404C10.465 7.52104 10.001 7.00004 9.403 7.00004Z" fill="white" />
                    </svg>
                    </a>
                    <a href={`//${shop?.instagram_link}`} target='_blank'><svg className={classes.socialMedia} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 0C2.239 0 0 2.239 0 5V13C0 15.761 2.239 18 5 18H13C15.761 18 18 15.761 18 13V5C18 2.239 15.761 0 13 0H5ZM15 2C15.552 2 16 2.448 16 3C16 3.552 15.552 4 15 4C14.448 4 14 3.552 14 3C14 2.448 14.448 2 15 2ZM9 4C11.761 4 14 6.239 14 9C14 11.761 11.761 14 9 14C6.239 14 4 11.761 4 9C4 6.239 6.239 4 9 4ZM9 6C8.20435 6 7.44129 6.31607 6.87868 6.87868C6.31607 7.44129 6 8.20435 6 9C6 9.79565 6.31607 10.5587 6.87868 11.1213C7.44129 11.6839 8.20435 12 9 12C9.79565 12 10.5587 11.6839 11.1213 11.1213C11.6839 10.5587 12 9.79565 12 9C12 8.20435 11.6839 7.44129 11.1213 6.87868C10.5587 6.31607 9.79565 6 9 6Z" fill="white" />
                    </svg>

                    </a>
                </Grid>

            </Grid>

            {/* responsive */}

            {/* <Grid container spacing={4} sx={{ paddingTop: '48px', paddingBottom: '24px', display: { xs: 'block', md: 'none' } }}> */}
            <Box sx={{ display: { xs: 'block', md: 'none' }, paddingTop: '48px', paddingBottom: '24px' }}>


                <Container>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p className={classes.bigTextRes}> Popular Collections</p>

                        <img src={viewMore}
                            onClick={e => { display == 'none' ? (setDisplay('block'), (e.currentTarget.src = viewLess)) : (setDisplay('none'), (e.currentTarget.src = viewMore)) }}
                        />


                    </Box>

                    <Box sx={{ display: { display } }}>

                        {
                            category?.map(category => <><p key={category?.category_id} className={classes.smTextRes} onClick={() => handleClick(category?.category_id)}> {category?.category}</p><br /></>)
                        }


                    </Box>

                </Container>



                <Container >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p className={classes.bigTextRes}>Contact us</p>
                        <img src={viewMore}
                            onClick={e => { displayContact == 'none' ? (setDisplayContact('block'), (e.currentTarget.src = viewLess)) : (setDisplayContact('none'), (e.currentTarget.src = viewMore)) }}
                        />
                    </Box>


                    <Box sx={{ display: displayContact == 'none' ? 'none' : 'block' }}>


                        <p className={classes.smText}>
                            <Box sx={{ marginRight: '8px' }}>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" >
                                    <path d="M12.4223 9.44507L10.5173 9.22757C10.0598 9.17507 9.60979 9.33257 9.28729 9.65507L7.90729 11.0351C5.78479 9.95507 4.04479 8.22257 2.96479 6.09257L4.35229 4.70507C4.67479 4.38257 4.83229 3.93257 4.77979 3.47507L4.56229 1.58507C4.47229 0.827568 3.83479 0.257568 3.06979 0.257568H1.77229C0.924787 0.257568 0.219786 0.962568 0.272286 1.81007C0.669786 8.21507 5.79229 13.3301 12.1898 13.7276C13.0373 13.7801 13.7423 13.0751 13.7423 12.2276V10.9301C13.7498 10.1726 13.1798 9.53507 12.4223 9.44507Z" fill="#79747E" />
                                </svg>
                            </Box>
                            {shop?.phone}</p>
                        <p className={classes.smText}>
                            <Box sx={{ marginRight: '8px' }}>
                                <svg width="16" height="12" viewBox="0 0 16 12" fill="none" >
                                    <path d="M14 0H2C1.175 0 0.5075 0.675 0.5075 1.5L0.5 10.5C0.5 11.325 1.175 12 2 12H14C14.825 12 15.5 11.325 15.5 10.5V1.5C15.5 0.675 14.825 0 14 0ZM13.7 3.1875L8.3975 6.5025C8.1575 6.6525 7.8425 6.6525 7.6025 6.5025L2.3 3.1875C2.1125 3.0675 2 2.865 2 2.6475C2 2.145 2.5475 1.845 2.975 2.1075L8 5.25L13.025 2.1075C13.4525 1.845 14 2.145 14 2.6475C14 2.865 13.8875 3.0675 13.7 3.1875Z" fill="#79747E" />
                                </svg>
                            </Box>
                            {shop?.email}</p>
                    </Box>
                </Container>



                <Container>
                    <p className={classes.bigTextRes}>Follow us</p>
                    <Box sx={{ display: 'flex' }}>
                        {/* {shop?.facebook_link} */}
                        <a href={`//${shop?.facebook_link}`} rel="noreferrer" target='_blank'><svg className={classes.socialMedia} width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.403 7.00004H7V5.00004C7 3.96804 7.084 3.31804 8.563 3.31804H9.431C9.983 3.31804 10.431 2.87004 10.431 2.31804V1.06404C10.431 0.541037 10.03 0.0940375 9.508 0.0590375C8.904 0.0180375 8.299 -0.000962531 7.693 3.74688e-05C4.98 3.74688e-05 3 1.65704 3 4.69904V7.00004H1C0.448 7.00004 0 7.44804 0 8.00004V10C0 10.552 0.448 11 1 11L3 10.999V19C3 19.552 3.448 20 4 20H6C6.552 20 7 19.552 7 19V10.997L9.174 10.996C9.682 10.996 10.109 10.615 10.167 10.11L10.396 8.11404C10.465 7.52104 10.001 7.00004 9.403 7.00004Z" fill="white" />
                        </svg>
                        </a>
                        <a href={`//${shop?.instagram_link}`} target='_blank'><svg className={classes.socialMedia} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 0C2.239 0 0 2.239 0 5V13C0 15.761 2.239 18 5 18H13C15.761 18 18 15.761 18 13V5C18 2.239 15.761 0 13 0H5ZM15 2C15.552 2 16 2.448 16 3C16 3.552 15.552 4 15 4C14.448 4 14 3.552 14 3C14 2.448 14.448 2 15 2ZM9 4C11.761 4 14 6.239 14 9C14 11.761 11.761 14 9 14C6.239 14 4 11.761 4 9C4 6.239 6.239 4 9 4ZM9 6C8.20435 6 7.44129 6.31607 6.87868 6.87868C6.31607 7.44129 6 8.20435 6 9C6 9.79565 6.31607 10.5587 6.87868 11.1213C7.44129 11.6839 8.20435 12 9 12C9.79565 12 10.5587 11.6839 11.1213 11.1213C11.6839 10.5587 12 9.79565 12 9C12 8.20435 11.6839 7.44129 11.1213 6.87868C10.5587 6.31607 9.79565 6 9 6Z" fill="white" />
                        </svg>

                        </a>
                    </Box>
                </Container>

            </Box >

            {/* </Grid> */}
            < a href="https://www.bonikapp.com/" target="_blank" className={classes.poweredByLink} > Powered by Bonik</a >
        </>
    );
};

export default FooterTechne;