import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import axios from '../../../../InstanceAxios/InstanceAxios'
import { styled } from '@mui/material/styles';
import AllProductsRetro from '../AllProductsRetro/AllProductsRetro';
import FooterRetro from '../../SharedRetro/FooterRetro/FooterRetro';
import NavBarRetro from '../../SharedRetro/NavBarRetro/NavBarRetro';



const Img = styled('img')(({ theme }) => ({

    objectFit: 'contain',
    width: '100%',
    height: '100%',
    marginTop: '5%',

    [theme.breakpoints.down('md')]: {
        marginTop: '15%'
    },

}));

const HomeRetro = () => {


    const navigate = useNavigate()
    const [bannerImg, setBannerImg] = useState({})

    let shop = useSelector((state) => state.product.shop)

    useEffect(() => {

        const fetchBanner = async () => {

            const response = await axios.get(`/merchants/getShopBanners/${shop?.shop_id}`)

            setBannerImg(response.data)
        }
        fetchBanner()

    }, [shop?.shop_id])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <NavBarRetro />
            {
                bannerImg?.homepage_banner ? (
                    // <img className={classes.img} src={bannerImg?.homepage_banner} />
                    <Img src={bannerImg?.homepage_banner} />
                ) : (
                    bannerImg?.homepage_banner !== null && <CircularProgress
                        sx={{ color: '#000000', marginTop: '85px' }}
                        size={40}
                        thickness={4} />
                )
            }

            <AllProductsRetro />
            <hr />
            <FooterRetro />
        </>
    );
};

export default HomeRetro;