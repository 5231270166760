import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { makeStyles } from '@material-ui/styles';
import ShopNowButtonTechne from '../../Buttons/ShopNowButtonTechne/ShopNowButtonTechne';

const useStyles = makeStyles({
    cardStyle: {

        maxHeight: '282px',
        maxWidth: '182px',
        width: 'auto',
        height: 'auto',
        textAlign: 'center',
        aspectRatio: '1/1',
        // padding: '10px',
        // border: '1px solid #E6E6E6',
        // borderRadius: '4px',



    },
    img: {

        objectFit: 'cover',

        width: '100%',
        height: '100%',
        // objectPosition: 'bottom'


    },
    title: {
        textAlign: 'left',

        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 500,
        fontSize: '3.5vw',
        lineHeight: '20px',
        marginTop: '10px',
        marginBottom: '6px'
    },

    cardContent: {
        fontWeight: 500,
        fontSize: '3vw',
        marginBottom: '0px'
    },
    price: {
        fontWeight: 500,
        fontSize: '3vw',
        lineHeight: '24px',
        marginRight: '5px'

    },
    outOfStock: {
        backgroundColor: '#000000',
        color: '#FFFFFF',
        fontFamily: 'Quicksand',
        fontSize: '10px',
        border: '1px solid #FFFFFF',
        position: 'absolute',
        marginTop: '12px',
        paddingTop: '3px',
        paddingBottom: '3px',
        paddingLeft: '5px',
        paddingRight: '5px',

    },
    bdt: {
        fontFamily: 'NotoSansBengali'
    }


});

const CardResponsiveTechne = ({ product }) => {

    const classes = useStyles();

    const navigate = useNavigate();

    let shop = useSelector((state) => state?.product?.shop)



    shop = JSON.parse(localStorage.getItem('shop'));
    const name = process.env.React_App_NAME

    const hostName = window.location.hostname;
    let content;

    if (product.percentage_off) {
        content = <><p className={classes.price} style={{ color: '#000000', marginRight: '6px' }}><span className={classes.bdt}>৳ </span>{product.unit_discounted_price}</p>
            <del style={{ color: ' #95989A', marginRight: '4px', fontSize: '3vw' }}><span className={classes.bdt}>৳ </span>{product.unit_selling_price}</del>
            <p style={{ color: '#00DD64' }} className={classes.cardContent}>{product.percentage_off}% off</p></>
    }


    else if (product.amount_off) {
        content = <><p className={classes.price} style={{ color: '#000000', marginRight: '6px' }}><span className={classes.bdt}>৳ </span>{product.unit_discounted_price}</p> <del style={{ color: ' #95989A', marginRight: '4px', fontSize: '3vw' }}><span className={classes.bdt}>৳ </span>{product.unit_selling_price}</del> <p style={{ color: '#00DD64' }} className={classes.cardContent}><span className={classes.bdt}>৳ </span>{product.amount_off} off</p></>
    }


    else {
        content = <p className={classes.price} style={{ color: '#000000' }}><span className={classes.bdt}>৳ </span>{product.unit_selling_price}</p>
    }


    let contentOutOfStock;
    if (product?.stock[0]?.number_of_stocks === 0) {
        contentOutOfStock = 'Out of Stock'
    }


    const handleClick = () => {
        // console.log(product?.product_id);
        hostName == name ? navigate(`/${shop?.shop_link}/products/${product?.product_id}`) : navigate(`/products/${product?.product_id}`)
    }


    return (
        <Box onClick={handleClick} className={classes.cardStyle}>
            <p className={classes.outOfStock}>{contentOutOfStock}</p>

            <Box sx={{ width: '100%', aspectRatio: '1/1' }}>
                {
                    product?.product_images[1] ?
                        <img className={classes.img} src={product?.product_images[0]?.image_url}
                            onMouseOver={e => (e.currentTarget.src = product?.product_images[1]?.image_url)}
                            onMouseOut={e => (e.currentTarget.src = product?.product_images[0]?.image_url)}
                        />
                        :
                        <img className={classes.img} src={product?.product_images[0]?.image_url} />
                    //product?.product_images.map(image => <img className={classes.img} src={image?.image_url} />)
                }
            </Box>
            <p className={classes.title}>{product.product_name}</p>
            <Box className={classes.price} sx={{ display: 'flex' }}>{content}</Box>

            <ShopNowButtonTechne product_id={product.product_id}></ShopNowButtonTechne>

        </Box>
    );
};

export default CardResponsiveTechne;