import './App.css';
import { Routes, Route, BrowserRouter, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from 'react-redux';
import { fetchShop } from './redux/slices/productSlice';
import { lazy, Suspense, useEffect } from 'react';
import { getTotals } from './redux/slices/cartSlice';
import { MessengerChat } from "react-messenger-chat-plugin";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga4";

const Successful = lazy(() => import('./Themes/Singularity/Bag/Successful/Successful'));
const BagInfo = lazy(() => import('./Themes/Singularity/Bag/BagInfo/BagInfo'))
const ProductDetails = lazy(() => import('./Themes/Singularity/ProductDetails/ProductDetails/ProductDetails'));
const NewArrivalPage = lazy(() => import('./Themes/Singularity/NewArrivalPage/NewArrivalPage/NewArrivalPage'));
const AllProductsPage = lazy(() => import('./Themes/Singularity/AllProductsPage/AllProductsPage/AllProductsPage'));
const CollectionsPage = lazy(() => import('./Themes/Singularity/CollectionsPage/CollectionsPage/CollectionsPage'));
const CategoryDetailsPage = lazy(() => import('./Themes/Singularity/CategoryDetailsPage/CategoryDetailsPage'));
const BagResponsive = lazy(() => import('./Themes/Singularity/Bag/BagResponsive/BagResponsive'));
const NotFoundPage = lazy(() => import('./Themes/Singularity/Shared/NotFoundPage/NotFoundPage'));
const Menu = lazy(() => import('./Themes/Singularity/Shared/Menu/Menu'));
const Home = lazy(() => import('./Themes/Singularity/Home/Home/Home'));


const HomeTechne = lazy(() => import('./Themes/Techne/HomeTechne/HomeTechne/HomeTechne.js'))
const ProductDetailsTechne = lazy(() => import('./Themes/Techne/ProductDetailsTechne/ProductDetailsTechne/ProductDetailsTechne'))


import { CircularProgress } from '@mui/material';
import CollectionsPageTechne from './Themes/Techne/CollectionsPageTechne/CollectionsPageTechne/CollectionsPageTechne';
import CollectionDetailsPageTechne from './Themes/Techne/CollectionsPageTechne/CollectionDetailsPageTechne/CollectionDetailsPageTechne';
import BagResponSiveTechne from './Themes/Techne/BagTechne/BagResponsiveTechne/BagResponSiveTechne';
import BagInfoTechne from './Themes/Techne/BagTechne/BagInfoTechne/BagInfoTechne';
import MenuTechne from './Themes/Techne/SharedTechne/MenuTechne/MenuTechne';
import SuccessfulTechne from './Themes/Techne/BagTechne/SuccessfullTechne/SuccessfulTechne';
import BagInfoSecStep from './Themes/Singularity/Bag/BagInfoSecStep/BagInfoSecStep';
import BagInfoSecStepTechne from './Themes/Techne/BagTechne/BagInfoSecStepTechne/BagInfoSecStepTechne';
import Failed from './Themes/Singularity/Bag/Failed/Failed';
import Cancelled from './Themes/Singularity/Bag/Cancelled/Cancelled';
import CancelledTechne from './Themes/Techne/BagTechne/CancelledTechne/CancelledTechne';
import FailedTechne from './Themes/Techne/FailedTechne/FailedTechne';
import { RETRO, SINGULARITY, TECHNE } from './Constants';

import HomeRetro from './Themes/Retro/HomeRetro/HomeRetro/HomeRetro';
import ProductDetailsRetro from './Themes/Retro/ProductDetailsRetro/ProductDetailsRetro/ProductDetailsRetro';
import CollectionsPageRetro from './Themes/Retro/CollectionsPageRetro/CollectionsPageRetro/CollectionsPageRetro';
import CollectionDetailsPageRetro from './Themes/Retro/CollectionsPageRetro/CollectionDetailsPageRetro/CollectionDetailsPageRetro';
import BagResponSiveRetro from './Themes/Retro/BagRetro/BagResponsiveRetro/BagResponSiveRetro';
import BagInfoRetro from './Themes/Retro/BagRetro/BagInfoRetro/BagInfoRetro';
import BagInfoSecStepRetro from './Themes/Retro/BagRetro/BagInfoSecStepRetro/BagInfoSecStepRetro';
import MenuRetro from './Themes/Retro/SharedRetro/MenuRetro/MenuRetro';
import SuccessfulRetro from './Themes/Retro/BagRetro/SuccessfulRetro/SuccessfulRetro';
import CancelledRetro from './Themes/Retro/BagRetro/CancelledRetro/CancelledRetro';
import FailedRetro from './Themes/Retro/BagRetro/FailedRetro/FailedRetro';


function App() {


  const hostName = window.location.hostname;

  const dispatch = useDispatch();

  const name = process.env.React_App_NAME;
  let shop = {}

  // const params = useParams();
  const path = window.location.pathname
  //const shopLink = path.split("/")
  //const shopLink = "kb"

  // console.log(shopLink[1]);

  useEffect(() => {
    // console.log(shopLink);
    console.log("val", hostName == name)
    dispatch(getTotals())
    hostName == name ? dispatch(fetchShop("kb")) : dispatch(fetchShop(hostName))

  }, [dispatch])

  shop = useSelector((state) => state.product.shop)
  // console.log(shop);


  if (shop?.facebook_pixel_id) {
    const advancedMatching = {};
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(shop?.facebook_pixel_id, advancedMatching, options);
  }

  if (shop?.google_analytics_measurement_id) {
    ReactGA.initialize(shop?.google_analytics_measurement_id);
  }

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{shop?.shop_name}</title>
        <meta name="name" content="0; URL=https://www.bonikapp.com/" />
        {/* <meta name={`//${shop.shop_name}`} content="0; URL=https://www.bonikapp.com/" /> */}
        <link rel="bonikApp" href="https://www.bonikapp.com/" />

      </Helmet>
      {shop?.facebook_page_id && <MessengerChat
        pageId={shop?.facebook_page_id}
        language="en_US"
        themeColor={"#F7634D"}
        bottomSpacing={10}
        loggedInGreeting=""
        loggedOutGreeting=""
        greetingDialogDisplay={"show"}
        debugMode={false}
        onMessengerShow={() => {
        }}
        onMessengerHide={() => {
        }}
        onMessengerDialogShow={() => {
        }}
        onMessengerDialogHide={() => {
        }}
        onMessengerMounted={() => {
        }}
        onMessengerLoad={() => {
        }}
      />}
      <BrowserRouter>

        {

          shop?.theme_id === SINGULARITY &&
          <Suspense fallback={<CircularProgress
            sx={{ color: '#FF6650' }}
            size={40}
            thickness={4} />}>
            <Routes>

              {
                hostName !== name ? (shop?.domain_status != 'active' && <Route path="/notFound" element={<NotFoundPage />} />) : (shop === null && <Route path="/notFound" element={<NotFoundPage />} />)
              }


              {
                hostName == name ? <Route path="/" element={<Home />} /> : <Route path="/" element={<Home />} />
              }

              {
                hostName == name ? <Route path="/kb/bagInfo" element={<BagInfo />} /> : <Route path="/bagInfo" element={<BagInfo />} />
              }
              {
                hostName == name ? <Route path="/kb/bagInfo" element={<BagInfo />} /> : <Route path="/bagInfo" element={<BagInfo />} />
              }
              {
                hostName == name ? <Route path="/invoice" element={<BagInfoSecStep />} /> : <Route path="/invoice" element={<BagInfoSecStep />} />
              }

              {
                hostName == name ? <Route path="/kb/newArrival" element={<NewArrivalPage />} /> : <Route path="/newArrival" element={<NewArrivalPage />} />
              }

              {
                hostName == name ? <Route path="/kb/allProducts" element={<AllProductsPage />} /> : <Route path="/allProducts" element={<AllProductsPage />} />
              }

              {
                hostName == name ? <Route path="/kb/collections" element={<CollectionsPage />} /> : <Route path="/collections" element={<CollectionsPage />} />
              }

              {
                hostName == name ? <Route path="/kb/bag" element={<BagResponsive />} /> : <Route path="/bag" element={<BagResponsive />} />
              }

              {
                hostName == name ? <Route path="/kb/menu" element={<Menu />} /> : <Route path="/menu" element={<Menu />} />
              }

              {
                hostName == name ? <Route path="/kb/successful" element={<Successful />} /> : <Route path="/successful" element={<Successful />} />
              }

              {
                hostName == name ? <Route path="/kb/failed" element={<Failed />} /> : <Route path="/failed" element={<Failed />} />
              }

              {
                hostName == name ? <Route path="/kb/cancelled" element={<Cancelled />} /> : <Route path="/cancelled" element={<Cancelled />} />
              }

              {
                hostName == name ? <Route path="/kb/products/:pid" element={<ProductDetails />} /> : <Route path="/products/:pid" element={<ProductDetails />} />
              }

              {
                hostName == name ? <Route path="/kb/categoryDetails/:cid" element={<CategoryDetailsPage />} /> : <Route path="/categoryDetails/:cid" element={<CategoryDetailsPage />} />
              }


            </Routes>
          </Suspense>
        }
        {
          shop?.theme_id === TECHNE &&
          <Suspense fallback={<CircularProgress
            sx={{ color: '#FF6650' }}
            size={40}
            thickness={4} />}>
            <Routes>

              {
                hostName !== name ? (shop?.domain_status != 'active' && <Route path="/notFound" element={<NotFoundPage />} />) : (shop === null && <Route path="/notFound" element={<NotFoundPage />} />)
              }
              {
                hostName == name ? <Route path="/" element={<HomeTechne />} /> : <Route path="/" element={<HomeTechne />} />
              }
              {
                hostName == name ? <Route path="/kb/products/:pid" element={<ProductDetailsTechne />} /> : <Route path="/products/:pid" element={<ProductDetailsTechne />} />
              }
              {
                hostName == name ? <Route path="/kb/collections" element={<CollectionsPageTechne />} /> : <Route path="/collections" element={<CollectionsPageTechne />} />
              }
              {
                hostName == name ? <Route path="/kb/categoryDetails/:cid" element={<CollectionDetailsPageTechne />} /> : <Route path="/categoryDetails/:cid" element={<CollectionDetailsPageTechne />} />
              }
              {
                hostName == name ? <Route path="/kb/bag" element={<BagResponSiveTechne />} /> : <Route path="/bag" element={<BagResponSiveTechne />} />
              }
              {
                hostName == name ? <Route path="/kb/bagInfo" element={<BagInfoTechne />} /> : <Route path="/bagInfo" element={<BagInfoTechne />} />
              }
              {
                hostName == name ? <Route path="/kb/invoice" element={<BagInfoSecStepTechne />} /> : <Route path="/invoice" element={<BagInfoSecStepTechne />} />
              }
              {
                hostName == name ? <Route path="/kb/menu" element={<MenuTechne />} /> : <Route path="/menu" element={<MenuTechne />} />
              }

              {
                hostName == name ? <Route path="/kb/successful" element={<SuccessfulTechne />} /> : <Route path="/successful" element={<SuccessfulTechne />} />
              }
              {
                hostName == name ? <Route path="/kb/cancelled" element={<CancelledTechne />} /> : <Route path="/cancelled" element={<CancelledTechne />} />
              }
              {
                hostName == name ? <Route path="/kb/failed" element={<FailedTechne />} /> : <Route path="/failed" element={<FailedTechne />} />
              }
            </Routes>
          </Suspense>
        }

        {
          shop?.theme_id === RETRO &&
          <Suspense fallback={<CircularProgress
            sx={{ color: '#FF6650' }}
            size={40}
            thickness={4} />}>
            <Routes>

              {
                hostName !== name ? (shop?.domain_status != 'active' && <Route path="/notFound" element={<NotFoundPage />} />) : (shop === null && <Route path="/notFound" element={<NotFoundPage />} />)
              }
              {
                hostName == name ? <Route path="/" element={<HomeRetro />} /> : <Route path="/" element={<HomeRetro />} />
              }
              {
                hostName == name ? <Route path="/kb/products/:pid" element={<ProductDetailsRetro />} /> : <Route path="/products/:pid" element={<ProductDetailsRetro />} />
              }
              {
                hostName == name ? <Route path="/kb/collections" element={<CollectionsPageRetro />} /> : <Route path="/collections" element={<CollectionsPageRetro />} />
              }
              {
                hostName == name ? <Route path="/kb/categoryDetails/:cid" element={<CollectionDetailsPageRetro />} /> : <Route path="/categoryDetails/:cid" element={<CollectionDetailsPageRetro />} />
              }
              {
                hostName == name ? <Route path="/kb/bag" element={<BagResponSiveRetro />} /> : <Route path="/bag" element={<BagResponSiveRetro />} />
              }
              {
                hostName == name ? <Route path="/kb/bagInfo" element={<BagInfoRetro />} /> : <Route path="/bagInfo" element={<BagInfoRetro />} />
              }
              {
                hostName == name ? <Route path="/kb/invoice" element={<BagInfoSecStepRetro />} /> : <Route path="/invoice" element={<BagInfoSecStepRetro />} />
              }
              {
                hostName == name ? <Route path="/kb/menu" element={<MenuRetro />} /> : <Route path="/menu" element={<MenuRetro />} />
              }

              {
                hostName == name ? <Route path="/kb/successful" element={<SuccessfulRetro />} /> : <Route path="/successful" element={<SuccessfulRetro />} />
              }
              {
                hostName == name ? <Route path="/kb/cancelled" element={<CancelledRetro />} /> : <Route path="/cancelled" element={<CancelledRetro />} />
              }
              {
                hostName == name ? <Route path="/kb/failed" element={<FailedRetro />} /> : <Route path="/failed" element={<FailedRetro />} />
              }
            </Routes>
          </Suspense>
        }



      </BrowserRouter>
    </div >
  );
}

export default App;
